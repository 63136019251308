*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul[role=list],
ol[role=list] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture,
svg,
video,
canvas {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}
body,
html {
  height: 100%;
  scroll-behavior: smooth;
}

.site_header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: black;
  position: fixed;
  transition: all 0.6s;
  z-index: 99;
  top: 0;
}
.site_header_home {
  top: -100%;
}
.site_header .top_header {
  width: 100%;
  height: 26px;
  background-color: #f3cd88;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
@media screen and (max-device-width: 640px) {
  .site_header .top_header {
    flex-direction: column;
    padding: 20px 0;
    height: auto;
  }
}
.site_header .top_header .phone_number {
  display: flex;
  align-items: center;
}
.site_header .top_header .phone_number a {
  color: #0a0a0a;
  font-size: 12px;
}
.site_header .top_header .contacts_info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.site_header .top_header .contacts_info .email {
  display: flex;
  align-items: center;
}
.site_header .top_header .contacts_info .email a {
  color: #0a0a0a;
  font-size: 12px;
}
@media screen and (max-device-width: 640px) {
  .site_header .top_header .contacts_info .fb_out {
    display: none;
  }
}
.site_header .logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
.site_header .logo_container img {
  height: 70px;
}
@media screen and (max-device-width: 640px) {
  .site_header .logo_container {
    display: none;
  }
}
.site_header .site_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}
.site_header .site_nav ul {
  display: flex;
  align-items: center;
  gap: 25px;
  list-style: none;
}
.site_header .site_nav ul li {
  padding-bottom: 15px;
}
.site_header .site_nav ul li a {
  color: white;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 15px;
}
.site_header .site_nav ul li a:hover {
  color: #f3cd88;
}
.site_header .site_nav ul li.has_sub_items {
  position: relative;
}
.site_header .site_nav ul li.has_sub_items a:after {
  content: "";
  width: 5px;
  height: 5px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  transform: rotate(45deg);
}
.site_header .site_nav ul li.has_sub_items a:hover:after {
  border-color: #f3cd88;
}
.site_header .site_nav ul li.has_sub_items .sub_menu {
  display: none;
  position: absolute;
  width: 250px;
  border-top: 3px solid #f3cd88;
  top: 100%;
  left: 0;
  background-color: black;
  padding: 10px 0;
  z-index: 100;
}
.site_header .site_nav ul li.has_sub_items .sub_menu a {
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  transition: all 0.3s;
}
.site_header .site_nav ul li.has_sub_items .sub_menu a:hover {
  color: #f3cd88;
}
.site_header .site_nav ul li.has_sub_items .sub_menu a:after {
  display: none;
}
.site_header .site_nav ul li.has_sub_items:hover .sub_menu {
  display: block;
}
.site_header .site_nav ul li.search {
  cursor: pointer;
}
@media screen and (max-device-width: 640px) {
  .site_header .site_nav ul li.search {
    display: none;
  }
}
.site_header .site_nav .search_box {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
  height: 100%;
  background-color: black;
  display: none;
}
.site_header .site_nav .search_box input {
  width: 100%;
  height: 100%;
  background-color: black;
  border: none;
  color: white;
  font-size: 15px;
  padding: 0 20px;
  outline: none;
}
.site_header .site_nav .search_box input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.site_header .site_nav .search_box .close {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.site_header .site_nav .search_box_show {
  display: block;
}
@media screen and (max-device-width: 640px) {
  .site_header .site_nav {
    display: none;
    position: absolute;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    top: 150px;
    background-color: #191919;
  }
  .site_header .site_nav ul {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0px;
  }
  .site_header .site_nav ul li {
    padding-bottom: 5px;
  }
  .site_header .site_nav ul li a {
    padding: 10px;
    color: #f3cd88;
  }
  .site_header .site_nav ul li.has_sub_items {
    position: relative;
  }
  .site_header .site_nav ul li.has_sub_items a:after {
    content: "";
    width: 5px;
    height: 5px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    transform: rotate(45deg);
  }
  .site_header .site_nav ul li.has_sub_items a:hover:after {
    border-color: #f3cd88;
  }
  .site_header .site_nav ul li.has_sub_items .sub_menu {
    display: block;
    position: static;
    width: 100%;
    border-top: 0;
    background-color: transparent;
    padding: 10px 0;
    z-index: 100;
  }
  .site_header .site_nav ul li.has_sub_items .sub_menu a {
    color: #f3cd88;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
    transition: all 0.3s;
  }
  .site_header .site_nav ul li.has_sub_items .sub_menu a:hover {
    color: #f3cd88;
  }
  .site_header .site_nav ul li.has_sub_items .sub_menu a:after {
    display: none;
  }
  .site_header .site_nav_open {
    display: block;
  }
}
.site_header .mobile_nav_toggle {
  display: none;
}
.site_header_show {
  top: 0;
}
@media screen and (max-device-width: 640px) {
  .site_header .mobile_nav_toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #191919;
    padding: 20px;
    color: #f3cd88;
  }
  .site_header_home {
    top: 0;
  }
}

.home_hero {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.home_hero .bg_container {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.home_hero .hero_logo {
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.home_hero .hero_logo img {
  height: 200px;
}
.home_hero .hero_top_line {
  position: absolute;
  width: 100%;
  top: 200px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMwMDAwMDAiPjxwYXRoIGQ9Ik0xMjgwIDE0MFYwSDBsMTI4MCAxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTEyODAgOThWMEgwbDEyODAgOTh6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 100% 100px;
  height: 100px;
  z-index: 10;
  transform: scale(1, 1);
}
.home_hero .hero_bottom_line {
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMwMDAwMDAiPjxwYXRoIGQ9Ik0wIDB2MTQwaDEyODBMMCAweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0wIDQydjk4aDEyODBMMCA0MnoiLz48L2c+PC9zdmc+);
  background-size: 100% 100px;
  height: 100px;
  z-index: 10;
  transform: scale(1, 1);
}
@media screen and (max-device-width: 640px) {
  .home_hero {
    margin-top: 180px;
  }
}

.home_generic {
  display: flex;
  justify-content: space-between;
}
.home_generic .home_generic_content {
  width: 65%;
}
.home_generic .home_generic_content h1 {
  font-size: 48px;
  color: rgba(255, 255, 255, 0.87) !important;
  font-weight: 200 !important;
  padding: 30px 0;
  border-bottom: 1px solid rgba(242, 204, 133, 0.3);
  margin-bottom: 40px;
}
.home_generic .home_generic_content h2 {
  font-size: 48px;
  color: rgba(255, 255, 255, 0.87) !important;
  font-weight: 200 !important;
}
.home_generic .home_generic_content p {
  color: #f9f9f9 !important;
  line-height: 1.4em;
  font-size: 27px;
  font-weight: 100 !important;
  padding-bottom: 15px;
}
@media screen and (max-device-width: 640px) {
  .home_generic .home_generic_content {
    width: 100%;
  }
}
.home_generic .home_generic_image {
  width: 30%;
  font-size: 48px;
  color: rgba(255, 255, 255, 0.87) !important;
  text-align: center;
  font-weight: 100;
  padding-top: 30px;
}
.home_generic .home_generic_image a {
  margin-top: 40px;
}
@media screen and (max-device-width: 640px) {
  .home_generic .home_generic_image {
    width: 100%;
  }
}
@media screen and (max-device-width: 640px) {
  .home_generic {
    flex-direction: column;
  }
}

.home_contact {
  padding: 40px 0;
  margin: 50px 0;
  border-bottom: 1px solid rgba(242, 204, 133, 0.3);
  border-top: 1px solid rgba(242, 204, 133, 0.3);
}
.home_contact p {
  font-size: 41px;
  color: rgba(255, 255, 255, 0.87) !important;
  line-height: 1.2em;
  text-align: center;
  font-weight: 200;
}
.home_contact p a {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.87) !important;
}
@media screen and (max-device-width: 640px) {
  .home_contact p a {
    display: block;
  }
}

.home_galleries {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.home_galleries a {
  position: relative;
}
.home_galleries a img {
  filter: grayscale(80%);
  aspect-ratio: 1/1;
  object-fit: cover;
}
.home_galleries a .label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: rgba(18, 18, 18, 0.75);
  width: 90%;
  padding: 8px 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
}
@media screen and (max-device-width: 640px) {
  .home_galleries {
    grid-template-columns: repeat(1, 1fr);
  }
}

.no-filter {
  filter: none !important;
}

.footer_contact {
  margin-top: 40px;
  width: 100%;
  background-color: #191919;
  padding: 40px 0;
}
.footer_contact .footer_contact_body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.footer_contact .footer_contact_body .title {
  font-size: 22px;
  color: #dddddd !important;
  display: block;
  margin-bottom: 30px;
}
.footer_contact .footer_contact_body .form .double_line {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
}
.footer_contact .footer_contact_body .form .double_line input {
  width: 100%;
  appearance: none;
  background-color: #eee;
  width: 100%;
  border-width: 0;
  border-radius: 0;
  color: #999;
  font-size: 14px;
  padding: 16px;
}
.footer_contact .footer_contact_body .form textarea {
  margin-top: 20px;
  -webkit-appearance: none;
  background-color: #eee;
  width: 100%;
  border-width: 0;
  border-radius: 0;
  color: #999;
  font-size: 14px;
  padding: 16px;
  height: 230px;
}
.footer_contact .footer_contact_body .form .submit {
  display: flex;
  justify-content: flex-end;
}
.footer_contact .footer_contact_body .form .submit button {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 200 !important;
  background-color: rgba(61, 61, 61, 0.85);
  text-transform: uppercase;
  padding: 8px 25px;
  border: 1px solid white;
  border-radius: 3px;
  transition: all 0.3s;
  position: relative;
  margin-top: 20px;
  display: inline-block;
}
.footer_contact .footer_contact_body .form .submit button:hover {
  background-color: rgba(255, 255, 255, 0.55);
}
@media screen and (max-device-width: 640px) {
  .footer_contact .footer_contact_body {
    grid-template-columns: repeat(1, 1fr);
  }
}

.site_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 15px 0;
}
.site_footer a {
  color: rgba(243, 205, 136, 0.77);
  font-weight: 200;
  font-size: 14px;
  transition: all 0.3s;
}
.site_footer a:hover {
  opacity: 0.7;
}

.copyrights {
  background-color: #191919;
  padding: 10px 0;
}
.copyrights .copyright_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media screen and (max-device-width: 640px) {
  .copyrights .copyright_content {
    flex-direction: column-reverse;
    gap: 10px;
    justify-content: center;
  }
}
.copyrights .copyright_content .content_inner {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: 100;
  text-align: center;
}
@media screen and (max-device-width: 640px) {
  .copyrights .copyright_content .content_inner {
    flex-direction: column;
    padding: 10px 0;
  }
}
.copyrights .copyright_content .content_inner a.shoresite {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  transition: all 0.3s;
  padding-left: 3px;
}
.copyrights .copyright_content .content_inner a.shoresite:hover {
  opacity: 0.8;
}
.copyrights .copyright_content .content_inner a.shoresite img {
  max-height: 25px;
}
.copyrights .facebook {
  margin-left: auto;
}
@media screen and (max-device-width: 640px) {
  .copyrights .facebook {
    margin-right: auto;
  }
}

.subpage_hero {
  width: 100%;
  height: 290px;
  margin-top: 184px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: relative;
}
.subpage_hero .subpage_hero_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  line-height: 1.3em;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 20px 0;
  width: 80%;
  max-width: 1080px;
  margin: auto;
  display: block;
  font-weight: 200;
  color: #282828;
  z-index: 3;
}
.subpage_hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.subpage_hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(189deg, rgba(20, 19, 19, 0.5) 0%, rgba(154, 160, 160, 0.5) 100%);
  z-index: 2;
}
.subpage_hero_fade_bg_free:after {
  display: none;
}
@media screen and (max-device-width: 640px) {
  .subpage_hero {
    margin-top: 110px;
  }
}

.subpage_content {
  background-color: #111111;
  padding: 30px 0;
  color: #f9f9f9;
}
.subpage_content.bg_light {
  background-color: #2b2b2b;
}
.subpage_content.bg_white {
  background-color: white;
}

.about_generic {
  display: flex;
  gap: 50px;
  line-height: 1.6em;
  font-size: 38px;
  font-weight: 200;
}
@media screen and (max-device-width: 640px) {
  .about_generic {
    flex-direction: column;
  }
}

.about_content h2 {
  font-size: 48px;
  color: rgba(255, 255, 255, 0.87) !important;
  font-weight: 200;
  margin: 30px 0 20px;
}
.about_content p {
  font-weight: 100;
  color: #f9f9f9;
  line-height: 1.4em;
  font-size: 27px;
  margin-bottom: 15px;
}

.testimonials_item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}
.testimonials_item .img_holder {
  width: 35%;
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
}
.testimonials_item .quote_content {
  background-color: white;
  box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  width: 60%;
  padding: 30px;
  position: relative;
}
.testimonials_item .quote_content blockquote {
  font-size: 24px;
  font-weight: 200;
  color: #282828;
  margin: 0;
  margin: 20px 0 30px;
  border-left: 5px solid #3a3a3a;
  padding-left: 20px;
  font-size: 18px;
  line-height: 1.7em;
  font-weight: 100;
}
.testimonials_item .quote_content .quote_name {
  color: #282828;
  font-weight: 500;
}
.testimonials_item .quote_content:after {
  content: "";
  width: 40px;
  height: 40px;
  left: 50%;
  position: absolute;
  top: -16px;
  background: #f5f5f5;
  margin-left: -16px;
  border-radius: 31px;
  z-index: 2;
  border: 1px solid #0c0c0c;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjU3IDguODJMMS4yOSA3LjU5NUMxLjg5NjY2IDcuMTI4MzMgMi40MSA2LjYzODMzIDIuODMgNi4xMjVDMy4yNSA1LjYxMTY2IDMuNDYgNS4wMjgzMyAzLjQ2IDQuMzc1QzMuNDYgNC4wOTUgMy40NDgzMyAzLjg1IDMuNDI1IDMuNjRDMy40MjUgMy40MDY2NiAzLjM5IDMuMjA4MzMgMy4zMiAzLjA0NUMzLjQzNjY2IDMuMDQ1IDMuNjM1IDMuMDQ1IDMuOTE1IDMuMDQ1QzQuMjE4MzMgMy4wNDUgNC40MTY2NiAzLjA0NSA0LjUxIDMuMDQ1QzQuNTEgMy40NDE2NyA0LjMxMTY2IDMuNzggMy45MTUgNC4wNkMzLjU0MTY2IDQuMzQgMy4xNTY2NiA0LjQ4IDIuNzYgNC40OEMyLjI3IDQuNDggMS44MjY2NiA0LjMxNjY2IDEuNDMgMy45OUMxLjA1NjY2IDMuNjQgMC44Njk5OTUgMy4xNjE2NiAwLjg2OTk5NSAyLjU1NUMwLjg2OTk5NSAxLjkwMTY3IDEuMDY4MzMgMS4zNjUgMS40NjUgMC45NDQ5OTlDMS44NjE2NiAwLjUwMTY2NSAyLjQxIDAuMjc5OTk5IDMuMTEgMC4yNzk5OTlDMy44NTY2NiAwLjI3OTk5OSA0LjQ3NSAwLjUxMzMzMiA0Ljk2NSAwLjk3OTk5OEM1LjQ1NSAxLjQyMzMzIDUuNyAyLjE4MTY2IDUuNyAzLjI1NUM1LjcgNC4yMzUgNS4zMzgzMyA1LjIzODMzIDQuNjE1IDYuMjY1QzMuODkxNjYgNy4yOTE2NyAyLjg3NjY2IDguMTQzMzMgMS41NyA4LjgyWk04LjE1IDguODJMNy44NyA3LjU5NUM4LjQ3NjY2IDcuMTI4MzMgOC45OSA2LjYzODMzIDkuNDEgNi4xMjVDOS44MyA1LjYxMTY2IDEwLjA0IDUuMDI4MzMgMTAuMDQgNC4zNzVDMTAuMDQgNC4wOTUgMTAuMDI4MyAzLjg1IDEwLjAwNSAzLjY0QzEwLjAwNSAzLjQwNjY2IDkuOTcgMy4yMDgzMyA5Ljg5OTk5IDMuMDQ1QzEwLjAxNjcgMy4wNDUgMTAuMjE1IDMuMDQ1IDEwLjQ5NSAzLjA0NUMxMC43OTgzIDMuMDQ1IDEwLjk5NjcgMy4wNDUgMTEuMDkgMy4wNDVDMTEuMDkgMy40NDE2NyAxMC44OTE3IDMuNzggMTAuNDk1IDQuMDZDMTAuMTIxNyA0LjM0IDkuNzM2NjYgNC40OCA5LjM0IDQuNDhDOC44NSA0LjQ4IDguNDA2NjYgNC4zMTY2NiA4LjAxIDMuOTlDNy42MzY2NiAzLjY0IDcuNDUgMy4xNjE2NiA3LjQ1IDIuNTU1QzcuNDUgMS45MDE2NyA3LjY0ODMzIDEuMzY1IDguMDQ0OTkgMC45NDQ5OTlDOC40NDE2NiAwLjUwMTY2NSA4Ljk5IDAuMjc5OTk5IDkuNjkgMC4yNzk5OTlDMTAuNDM2NyAwLjI3OTk5OSAxMS4wNTUgMC41MTMzMzIgMTEuNTQ1IDAuOTc5OTk4QzEyLjAzNSAxLjQyMzMzIDEyLjI4IDIuMTgxNjYgMTIuMjggMy4yNTVDMTIuMjggNC4yMzUgMTEuOTE4MyA1LjIzODMzIDExLjE5NSA2LjI2NUMxMC40NzE3IDcuMjkxNjcgOS40NTY2NiA4LjE0MzMzIDguMTUgOC44MloiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: center center;
}
.testimonials_item_rtl {
  flex-direction: row-reverse;
}
.testimonials_item_rtl .img_holder {
  width: 60%;
}
.testimonials_item_rtl .quote_content {
  width: 35%;
}
@media screen and (max-device-width: 640px) {
  .testimonials_item {
    flex-direction: column;
  }
  .testimonials_item .img_holder {
    width: 100%;
  }
  .testimonials_item .quote_content {
    width: 100%;
  }
}

.testimonails_padding_line {
  height: 180px;
}

.testimonials_navigator {
  position: fixed;
  right: 0;
  top: 250px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: fixed;
  right: 0;
  top: 50%;
  width: 40px;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
}
.testimonials_navigator a {
  padding: 7px;
  position: relative;
}
.testimonials_navigator a:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7px;
  height: 7px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.all_galleries {
  padding: 70px 0;
}

.gallery_title {
  width: 100%;
  padding: 40px;
  color: #cecece !important;
  font-weight: 200;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dress_guidelines_heading {
  width: 100%;
  background-color: black;
  color: #cecece;
  padding: 35px 0;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
}

.dress_guidelines_content {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin: 50px 0;
}
.dress_guidelines_content .img_holder {
  width: 30%;
}
.dress_guidelines_content .content_holder {
  width: 70%;
  font-weight: 200;
}
.dress_guidelines_content .content_holder .content_title {
  font-size: 27px;
  color: #929292;
  display: block;
  margin-bottom: 20px;
}
.dress_guidelines_content .content_holder p {
  color: #e2e2e2;
  line-height: 2em;
}
.dress_guidelines_content .content_holder p strong {
  color: white;
}
.dress_guidelines_content_rtl {
  flex-direction: row-reverse;
}
@media screen and (max-device-width: 640px) {
  .dress_guidelines_content {
    flex-direction: column;
  }
  .dress_guidelines_content .img_holder {
    width: 100%;
  }
  .dress_guidelines_content .content_holder {
    width: 100%;
  }
}

.input_holder {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.input_holder input {
  width: 100%;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
  color: #e2e2e2;
  font-size: 14px;
  padding: 16px;
  background-color: #282828;
  appearance: none;
  outline: none;
  border: 0;
}
.input_holder_split input {
  width: 50%;
}

.gift_certificates_submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.gift_certificates_submit button {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 200 !important;
  background-color: rgba(61, 61, 61, 0.85);
  text-transform: uppercase;
  padding: 8px 25px;
  border: 1px solid white;
  border-radius: 3px;
  transition: all 0.3s;
  position: relative;
  margin-top: 20px;
  display: inline-block;
}
.gift_certificates_submit button:hover {
  background-color: rgba(255, 255, 255, 0.55);
}

.contact_body {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  padding: 40px 0;
}
.contact_body .contact_form {
  width: 70%;
}
.contact_body .contact_form h2 {
  font-size: 38px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 30px;
}
.contact_body .contact_form .form .double_line {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
}
.contact_body .contact_form .form .double_line input {
  width: 100%;
  appearance: none;
  background-color: #eee;
  width: 100%;
  border-width: 0;
  border-radius: 0;
  color: #999;
  font-size: 14px;
  padding: 16px;
}
.contact_body .contact_form .form textarea {
  margin-top: 20px;
  -webkit-appearance: none;
  background-color: #eee;
  width: 100%;
  border-width: 0;
  border-radius: 0;
  color: #999;
  font-size: 14px;
  padding: 16px;
  height: 230px;
}
.contact_body .contact_form .form .submit {
  display: flex;
  justify-content: flex-end;
}
.contact_body .contact_form .form .submit button {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 200 !important;
  background-color: rgba(61, 61, 61, 0.85);
  text-transform: uppercase;
  padding: 8px 25px;
  border: 1px solid white;
  border-radius: 3px;
  transition: all 0.3s;
  position: relative;
  margin-top: 20px;
  display: inline-block;
}
.contact_body .contact_form .form .submit button:hover {
  background-color: rgba(255, 255, 255, 0.55);
}
.contact_body .contact_content {
  width: 30%;
}
.contact_body .contact_content .title {
  margin-bottom: 15px;
  display: block;
  color: white;
  font-weight: 300;
  font-size: 18px;
}
.contact_body .contact_content p {
  font-weight: 200;
  font-size: 16px;
  padding-bottom: 1em;
  line-height: 2em;
}
.contact_body .contact_content p a {
  color: #efc569;
}
@media screen and (max-device-width: 640px) {
  .contact_body {
    flex-direction: column-reverse;
  }
  .contact_body .contact_form {
    width: 100%;
  }
  .contact_body .contact_content {
    width: 100%;
  }
}

.get_directions {
  background-color: #282828;
  width: 100%;
  padding: 30px 0;
  font-size: 48px;
  color: rgba(255, 255, 255, 0.87) !important;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.privacy_policy {
  font-weight: 300 !important;
  color: #000;
  margin-top: 250px;
}
.privacy_policy h2 {
  font-size: 32px;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 200;
}
.privacy_policy p {
  padding-bottom: 1em;
  line-height: 2em;
  font-weight: 200;
}

.sitemap {
  font-weight: 300 !important;
  color: #000;
  margin-top: 250px;
}
.sitemap h2 {
  font-size: 32px;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 200;
}
.sitemap a {
  color: #efc569;
}
.sitemap ul {
  list-style: none;
}
.sitemap ul li {
  padding: 8px 0;
}
.sitemap ul li ul {
  padding-left: 10px;
}

.plain_content {
  margin-top: 250px;
  font-weight: 300 !important;
  color: #000;
}

.result_item {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.result_item a {
  color: #000;
  font-size: 32px;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 200;
}
.result_item date {
  font-size: 16px;
  font-weight: 200;
}

.carousel-container {
  width: 80%;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--lx-gap);
}
.carousel-container .carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.carousel-container .carousel .item {
  opacity: 0;
  width: 100%;
  height: 100%;
  display: none;
  transition: opacity 0.5s ease-in-out;
}
.carousel-container .carousel .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.carousel-container .carousel .item.active {
  opacity: 1;
  display: block;
}
.carousel-container .btn {
  padding: 1em 2em;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  transition: transform 0.2s ease-in-out;
}
.carousel-container .btn:active, .carousel-container .btn:focus {
  transform: translateY(-50%) scale(0.9);
}
.carousel-container .btn:hover {
  transform: translateY(-50%) scale(0.96);
}
.carousel-container .prev {
  left: 0%;
}
.carousel-container .next {
  right: 0%;
}
.carousel-container .dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  top: -30px;
}
.carousel-container .dots .dot {
  cursor: pointer;
  height: 7px;
  width: 7px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.2s ease;
  border-radius: 50%;
}
.carousel-container .dots .dot.active, .carousel-container .dots .dot:hover {
  background-color: #ffffe6;
}

body {
  font-family: "Albert Sans", serif;
  font-optical-sizing: auto;
  background-color: black;
}

a {
  text-decoration: none;
}
a.lbox {
  position: relative;
  display: block;
}
a.lbox:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
}
a.lbox:after {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: 35px;
  opacity: 0;
  transition: all 0.3s;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.4562 18.4353L11.1562 12.1353C10.6562 12.5353 10.0812 12.852 9.4312 13.0853C8.7812 13.3186 8.08953 13.4353 7.3562 13.4353C5.53953 13.4353 4.00203 12.8061 2.7437 11.5478C1.48537 10.2895 0.856201 8.75197 0.856201 6.9353C0.856201 5.11864 1.48537 3.58114 2.7437 2.3228C4.00203 1.06447 5.53953 0.435303 7.3562 0.435303C9.17287 0.435303 10.7104 1.06447 11.9687 2.3228C13.227 3.58114 13.8562 5.11864 13.8562 6.9353C13.8562 7.66864 13.7395 8.3603 13.5062 9.0103C13.2729 9.6603 12.9562 10.2353 12.5562 10.7353L18.8562 17.0353L17.4562 18.4353ZM7.3562 11.4353C8.6062 11.4353 9.6687 10.9978 10.5437 10.1228C11.4187 9.2478 11.8562 8.1853 11.8562 6.9353C11.8562 5.6853 11.4187 4.6228 10.5437 3.7478C9.6687 2.8728 8.6062 2.4353 7.3562 2.4353C6.1062 2.4353 5.0437 2.8728 4.1687 3.7478C3.2937 4.6228 2.8562 5.6853 2.8562 6.9353C2.8562 8.1853 3.2937 9.2478 4.1687 10.1228C5.0437 10.9978 6.1062 11.4353 7.3562 11.4353ZM6.3562 9.9353V7.9353H4.3562V5.9353H6.3562V3.9353H8.3562V5.9353H10.3562V7.9353H8.3562V9.9353H6.3562Z' fill='white'/%3E%3C/svg%3E%0A");
}
a.lbox:hover:before {
  opacity: 1;
}
a.lbox:hover:after {
  opacity: 0.5;
}
a.cta_button {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 200 !important;
  background-color: rgba(61, 61, 61, 0.85);
  text-transform: uppercase;
  padding: 8px 25px;
  border: 1px solid white;
  border-radius: 3px;
  transition: all 0.3s;
  position: relative;
  margin-top: 20px;
  display: inline-block;
}
a.cta_button:hover {
  background-color: rgba(255, 255, 255, 0.55);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll_top {
  position: fixed;
  right: -40px;
  bottom: 100px;
  width: 40px;
  height: 40px;
  background-color: black;
  border: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
}
.scroll_top_show {
  right: 0;
}

.overlay_selection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 999999;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.overlay_selection .overlay_content {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 100px;
  margin-top: 150px;
}
.overlay_selection .overlay_content a {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
}
.overlay_selection .overlay_content .g_portraits {
  color: white;
}
.overlay_selection .overlay_content .certificates {
  color: #efc569;
}
@media screen and (max-device-width: 640px) {
  .overlay_selection .overlay_content {
    flex-direction: column;
  }
  .overlay_selection .overlay_content a {
    width: 100%;
    text-align: center;
  }
}
.overlay_selection.show {
  display: block;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

@media (min-width: 325px) {
  .container {
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1140px;
  }
}

.floating-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}
.floating-button .btn {
  display: inline-block;
  height: 35px;
  background-color: #007bff;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 35px;
  border-radius: 6px;
  padding: 0 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  transition: all 0.3s ease;
}
.floating-button .btn:hover {
  background-color: #0056b3;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .floating-button {
    left: 50%;
    transform: translateX(-50%);
  }
}
.alert {
  padding: 10px;
  margin-bottom: 20px;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}
